
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"
import { routeToProduct } from "@/helpers/routing"

export default {
    name: "BusinessPlan",
    components: { Card, Button },
    data: () => ({
        routeToProduct,
    }),
}
